import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
// TODO: update css in /app/assets when react-datepicker changes
// import "react-datepicker/dist/react-datepicker.css";

export default class RailsDatePicker extends React.Component {
  static propTypes = {
    inputId: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({
      value: this.props.defaultValue ? new Date(this.props.defaultValue) : null,
    });
  }

  render() {
    const thisComponent = this;

    return (
      <div className="text-left">
        <label>Deadline After</label>
        <div className="my-2 form-group">
          <DatePicker className="form-control" selected={this.state?.value} onChange={(date) => thisComponent.setState({value: date}, () => { document.getElementById(thisComponent.props.inputId).value = date }) } />
        </div>
      </div>
    );
  }
}
