import React from "react";
import PropTypes from "prop-types";
import voteService from "./voteService";
import toast from 'react-hot-toast';

export default class CallVoteEmoji extends React.Component {
  static propTypes = {
    call: PropTypes.object.isRequired,
    rootClass: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props);
    this.handleVote = this.handleVote.bind(this);
    this.destroyVote = this.destroyVote.bind(this);
  }

  componentDidMount() {
    this.setState({
        statefulVote: this.props.call.vote
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.call.id !== this.props.call.id) {
      this.setState({
        statefulVote: this.props.call.vote
      });
    }
  }

  destroyVote(voteFlag) {
    let thisComponent = this;

    if (this.state && this.state.isVoting) {
      toast('You are doing that too much.', { icon: '❗' });
      return;
    }

    this.setState({ isVoting: true });


    this.setState({ statefulVote: undefined });

    const onCatch = (e) => {
      thisComponent.setState({ isVoting: false });
      if (e && e.response && e.response.status === 422) {
        if (e.response.data.vote.errors.base) {
          toast(e.response.data.vote.errors.base, { icon: '❗' });
        }
      } else {
        toast('Something went wrong', { icon: '❗' });
      }
    };

    const onSuccess = (response) => {
      thisComponent.setState({
        isVoting: false
      });
    };

    voteService.destroyVote(
      {
        votable_id: this.props.call.id,
        votable_type: 'Call',
        vote_flag: voteFlag
      },
      { onCatch, onSuccess }
    );
  }

  handleVote(voteFlag) {
    let thisComponent = this;

    if (this && this.state && this.state.isVoting) {
      toast('You are doing that too much.', { icon: '❗' });
      return;
    }

    if (!document.getElementById('user-data').dataset?.id) {
      toast(
        "You must log in to like calls.",
        { icon: '❗' }
      );
      return;
    }

    this.setState({
      isVoting: true
    });

    let optimisticVote = (this && this.state && this.state.statefulVote) || {
        voter_id: document.getElementById('user-data').dataset.id,
        votable_id: this.props.call.id,
        vote_flag: voteFlag
      };

    optimisticVote.vote_flag = voteFlag;
    console.log('optimisticVote', optimisticVote);

    this.setState({
      statefulVote: optimisticVote
    });

    const onCatch = (e) => {
      thisComponent.setState({ isVoting: false });
      if (e &&  e.response && e.response.status === 422) {
        if (e.response.data.vote.errors.base) {
          toast(e.response.data.vote.errors.base, { icon: '❗' });
        }
      } else {
        toast('Something went wrong', { icon: '❗' });
      }
    };

    const onSuccess = (response) => {
      thisComponent.setState({
        isVoting: false
      });
    };

    voteService.createVote(
      {
        votable_id: this.props.call.id,
        votable_type: 'Call',
        vote_flag: voteFlag
      },
      { onCatch, onSuccess }
    );
  }

  render() {
    let thisComponent = this;
    let call = this.props.call;

    let votedOn = !!((this.state && this.state.statefulVote) ? this.state.statefulVote.vote_flag : false);

    return (
      <div className={this.props.rootClass} key={call.id}>
        {votedOn && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => (votedOn ? thisComponent.destroyVote(true) : thisComponent.handleVote(true)) } fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 cursor-pointer fill-current text-red-500">
          <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
        </svg>}
        {!votedOn && <svg xmlns="http://www.w3.org/2000/svg" onClick={() => (votedOn ? thisComponent.destroyVote(true) : thisComponent.handleVote(true)) } fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 cursor-pointer">
          <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
        </svg>}
      </div>
    );
  }
}
