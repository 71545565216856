import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};

const EmailCaptureModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showEmailSignUp, setShowEmailSignUp] = useState(false);

  useEffect(() => {
    const mainContent = document.querySelector('main');
    if (mainContent) {
      if (isOpen) {
        mainContent.style.filter = 'blur(5px)';
      } else {
        mainContent.style.filter = 'none';
      }
    }

    return () => {
      if (mainContent) {
        mainContent.style.filter = 'none';
      }
    };
  }, [isOpen]);

  const postUser = async () => {
    setIsLoading(true);
    setErrors({});
    try {
      let response;
      try {
        response = await axios.post('/api/v1/users', { 
          user: { 
            email,
            name: fullName,
            password,
            terms_of_service: acceptTerms
          } 
        });
      } catch (error) {
        console.error('Error creating user:', error);
        if (error.response && error.response.data && error.response.data.errors) {
          setErrors(error.response.data.errors);
        } else {
          setErrors({ general: 'An unexpected error occurred. Please try again.' });
        }
        return;
      }
      window.response = response;
      if (response.status == 200) {
        window.location.href = '/pricing';
      } else {
        console.error('Failed to create user');
        setErrors({ general: 'Failed to create user. Please try again.' });
      }
    } catch (error) {
      console.error('Error creating user:', error);
      setErrors({ general: 'An unexpected error occurred. Please try again.' });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 flex items-center justify-center">
          <div className="flex items-center justify-center p-4 text-center w-full">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-4xl transform rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all flex flex-col justify-between bg-white dark:bg-gray-900 relative border border-gray-300">
                <div className="flex flex-col items-center">
                  <Dialog.Title
                    as="h2"
                    className="text-2xl font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    Get Access For Free!
                  </Dialog.Title>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    No credit card required for the free plan.
                  </p>
                  <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                    aria-label="Close modal"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div className="mt-2 space-y-4 w-full max-w-2xl mx-auto">
                  {!showEmailSignUp && (
                    <>
                      <div className="flex flex-wrap justify-center -mx-2" dangerouslySetInnerHTML={{__html: document.getElementById('hidden-sign-in-button').innerHTML}}>
                      </div>

                      <div className="flex justify-center mt-0 mb-0">
                        <p>or</p>
                      </div>
                    </>
                  )}

                  {!showEmailSignUp ? (
                    <button
                      type="button"
                      className="btn btn-primary mx-auto block"
                      onClick={() => setShowEmailSignUp(true)}
                    >
                      Sign Up With Email
                    </button>
                  ) : (
                    <>
                      <div className="form-group">
                        <label htmlFor="fullName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Full Name</label>
                        <input
                          type="text"
                          id="fullName"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                          className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black dark:text-white bg-white dark:bg-gray-700 ${errors.name ? 'error' : ''}`}
                          placeholder="Enter your full name"
                        />
                        {errors.name && <p className="form-hint error">{errors.name[0]}</p>}
                      </div>
                      <div className="form-group">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Email</label>
                        <input
                          type="email"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black dark:text-white bg-white dark:bg-gray-700 ${errors.email ? 'error' : ''}`}
                          placeholder="Enter your email"
                        />
                        {errors.email && <p className="form-hint error">{errors.email[0]}</p>}
                      </div>
                      <div className="form-group">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">Password</label>
                        <input
                          type="password"
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black dark:text-white bg-white dark:bg-gray-700 ${errors.password ? 'error' : ''}`}
                          placeholder="Enter your password"
                        />
                        {errors.password && <p className="form-hint error">{errors.password[0]}</p>}
                      </div>
                      <div className="flex items-center">
                        <input
                          id="terms"
                          type="checkbox"
                          checked={acceptTerms}
                          onChange={(e) => setAcceptTerms(e.target.checked)}
                          className={`h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded ${errors.terms_of_service ? 'error' : ''}`}
                        />
                        <label htmlFor="terms" className="ml-2 block text-sm text-gray-900 dark:text-gray-300">
                          I agree to the <a href="/terms" className="text-indigo-600 hover:text-indigo-500">Terms of Service</a>
                        </label>
                      </div>
                      {errors.terms_of_service && <p className="form-hint error">{errors.terms_of_service[0]}</p>}
                      {errors.general && <p className="form-hint error">{errors.general}</p>}
                      <button
                        type="button"
                        className="btn btn-primary w-full"
                        onClick={postUser}
                        disabled={isLoading}
                      >
                        {isLoading ? 'Submitting...' : 'Sign Up'}
                      </button>
                    </>
                  )}

                  <p className="text-sm text-center text-gray-600 dark:text-gray-400">
                    Already a member? <a href="/users/sign_in" className="text-indigo-600 hover:text-indigo-500">Log In</a>
                  </p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
};

export default EmailCaptureModal;
