import React, {useState, useEffect} from "react";

const LoadingBar = () => {
    const [loadingPercentage, setLoadingPercentage] = useState(1);

    const percentages = () => {
      let current = 1;
      result = [];
        while(current <= loadingPercentage) {
            result.push(
              <div key={current} style={{ width: "1%", height: "100%", backgroundColor: "blue"}}></div>
            );
            current++;
        }

      return result;
    }

    const buildLoadingBar = () => {
        return (
            <div>
                <div>Loading...</div>
                <div style={{
                    display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "50px"
                    }}>
                    <div style={{display: "flex", flexDirection: "row", width: "80%", height:"20px", border: "2px solid grey", boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)" }}>
                      {percentages().map((percentageDiv) => { return percentageDiv })}
                    </div>
                </div>
            </div>
        )
        
      }

    useEffect(() => {
        if (loadingPercentage < 99) {
            setTimeout(
                () => { setLoadingPercentage(loadingPercentage + 1) },
                15
            )
        }
    }, [loadingPercentage])
    

    return (
        <div>{buildLoadingBar()}</div>
    )
}

export default LoadingBar;