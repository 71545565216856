import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import $ from 'jquery';

const Marker = ({ text }) => <div style={{fontWeight: 'bold'}}>✅</div>;

class SoloCallMap extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({
      calls: this.props.calls,
      center: {
        lat: this.props.calls[0][1],
        lng: this.props.calls[0][2]
      }
    });
  }

  static defaultProps = {
    center: {
      lat: (window.innerWidth > 1000 ? 30 : 39.307929),
      lng: (window.innerWidth > 1000 ? -26.193666 : -97.434730)
    },
    zoom: 0
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '200px', maxWidth: '100%', margin: '0 auto' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAjS6LskmYH-MtVc-nn0-xRoawtDi2KU0M' }}
          defaultCenter={{
            lat: this.props.calls[0][1],
            lng: this.props.calls[0][2]
          }}
          defaultZoom={this.props.zoom}
        >
          {this && this.state && this.state.calls && this.state.calls.map((call) => {
            return (
              <Marker
                key={call[0]}
                lat={call[1]}
                lng={call[2]}
                text={'c'}
              />
            )
          })}
        </GoogleMapReact>
        {/* [@call.id, @call.latitude, @call.longitude, @call.geo_approx] */}
        {this && this.state && this.state.calls && this.state.calls[0][3] && <small>
            <em>Location not exact. See original call webpage.</em>
          </small>}
      </div>
    );
  }
}

export default SoloCallMap;