import internalApiService from './internalApiService';

export default {
  createVote: (values, callbacks = {}) => internalApiService.post(`/api/v1/votes/`, {
    vote: {
      votable_id: values.votable_id,
      votable_type: values.votable_type,
      vote_flag: values.vote_flag,
    }
  }, callbacks),

  destroyVote: (values, callbacks = {}) => internalApiService.post(`/api/v1/votes/destroy_any`, {
    vote: {
      votable_id: values.votable_id,
      votable_type: values.votable_type,
      vote_flag: values.vote_flag
    }
  }, callbacks),
};