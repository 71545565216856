import React from "react";
import PropTypes from "prop-types";
import CallVoteEmoji from './CallVoteEmoji';
import $ from 'jquery';
import ProgressiveImage from 'react-progressive-graceful-image';

export default class HomepageCall extends React.Component {
  static propTypes = {
    call: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      showIframe: false,
      iframeLoading: true
    };
    this.domainFromUrl = this.domainFromUrl.bind(this);
    this.expandedDescription = this.expandedDescription.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    // TODO: Bring back the iframe functionality with an allowlist instead of an exclude list
  }

  domainFromUrl(url) {
    if (!!!url) {
      return '';
    }
    let domain = (new URL(url));
    domain = domain.hostname;
    return domain.toString().replace('www.','');
  }

  expandedDescription() {
    let result = this.props.call.expanded_description;
    let searchText = this.props.searchText;

    if (searchText) {
      result = result.replaceAll(new RegExp(searchText, 'gi'), (v) => { return `<strong>${v}</strong>` });
    }
    return `${result}`;
  }

  showEntryFee() {
    let call = this.props.call;
    return (call.entry_fee || call.entry_fee == 0) && !call.description.includes(`$${ Math.round(call.entry_fee / 100) }`)
  }

  handleLinkClick(e) {
    e.preventDefault();
    const { call } = this.props;
    
    if (call.external) {
      window.open(call.external_url, '_blank');
    } else {
      window.location.href = call.path;
    }
    
    $.ajax({
      method: 'PATCH',
      url: `/api/v1/public/calls/${call.id}`,
      data: { authenticity_token: document.querySelector('meta[name="csrf-token"]').getAttribute('content') }
    });
  }

  render() {
    let thisComponent = this;
    let call = this.props.call;

    return (
      <div className="my-12 p-4 border-solid border-x-2 border-slate-400" key={call.id}>
        <div className="">
          <div className="">
            <div className="">
                <div className="">
                    <div className="">
                      <div className="flex justify-between">
                        <div>
                          <h5 className="font-semibold">
                            {false && <small className='text-slate-400'>{this.domainFromUrl(call.external_url) || '✨ entryhaven.com'}</small>}
                            <a onClick={this.handleLinkClick} className='cursor-pointer'>
                                  {call.external && (
                                    <ProgressiveImage
                                        src={`https://www.google.com/s2/favicons?domain=${new URL(call.external_url).hostname}`}
                                        className="inline-block mr-2" 
                                        style={{width: '16px', height: '16px', display: 'inline-block'}}>
                                        {(src, loading) => (loading ? (<div className="ph-item mr-2" style={{padding: 0, height: '16px', width: '16px', display: 'inline-block' }}><div className="ph-picture" style={{padding: 0, height: '16px', width: '16px'}}></div></div>) : <img className="inline-block mr-2" style={{height: '16x', width: '16x'}} src={src} alt={new URL(call.external_url).hostname} />)}
                                    </ProgressiveImage>
                                  )}
                                  {call.name}
                                  { call.venue && (<h6 className="">@ {call.venue.id}</h6>) }
                            </a>
                          </h5>
                          <div>
                            <h5 className="font-semibold"><small><span className="text-slate-400">Closes {call.entry_deadline} <span className="text-slate-400"></span></span></small></h5>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>

            <br/>

            { call.description && (
              <div className={""} onClick={this.handleLinkClick}>
                <div className="cursor-pointer" dangerouslySetInnerHTML={{ __html: `${this.expandedDescription()} ${'<br/><br/>'}` }} />
              </div>
            ) }

            { this.showEntryFee() && <div>
              <span>- ${ Math.round(call.entry_fee / 100) } entry</span>
            </div> }

            { this.showEntryFee() && <br/> }

            <div className="flex justify-between">
              {<CallVoteEmoji call={call} rootClass="" />}

              <div>
                <a className="btn btn-secondary" onClick={this.handleLinkClick} href={call.external ? call.external_url : call.path}>
                  Apply
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
