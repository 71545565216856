import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import $ from 'jquery';

const Marker = ({ text }) => <div style={{fontWeight: 'bold'}}>✅</div>;

class SimpleMap extends Component {
  constructor(props) {
    super(props);
    this.getCalls = this.getCalls.bind(this);
  }

  componentDidMount() {
    this.getCalls();
  }

  getCalls() {
    let thisComponent = this;

    $.get("/api/v1/public/calls.json", { coordinates_only: true })
        .done(function(response) {
                  thisComponent.setState({
                    getError: false,
                    calls: response.records,
                  });
                })
        .fail(function(response) {
                // thisComponent.setState({ getError: 'Oops, something went wrong.'});
              })
        .always(function(response) {  });
  }

  static defaultProps = {
    center: {
      lat: (window.innerWidth > 1000 ? 30 : 39.307929),
      lng: (window.innerWidth > 1000 ? -26.193666 : -97.434730)
    },
    zoom: 0
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: (window.innerWidth > 800 ? '100%' : '100%'), margin: '0 auto' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyAjS6LskmYH-MtVc-nn0-xRoawtDi2KU0M' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          {this && this.state && this.state.calls && this.state.calls.map((call) => {
            return (
              <Marker
                key={call[0]}
                lat={call[1]}
                lng={call[2]}
                text={'c'}
              />
            )
          })}
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;