import React from "react";
import internalApiService from './internalApiService';
import DatePicker from "react-datepicker";
import toast from 'react-hot-toast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSliders } from '@fortawesome/free-solid-svg-icons'

// TODO: update css in /app/assets when react-datepicker changes
// import "react-datepicker/dist/react-datepicker.css";

export default class BaseCallSearch extends React.Component {

  constructor(props) {
    super(props);
    this.currentPage = this.currentPage.bind(this);
    this.toggleCallType = this.toggleCallType.bind(this);
    this.toggleCategory = this.toggleCategory.bind(this);
    this.selectedCallTypes = this.selectedCallTypes.bind(this);
    this.selectedCategories = this.selectedCategories.bind(this);
    this.toggleSpider = this.toggleSpider.bind(this);
    this.selectedSpiders = this.selectedSpiders.bind(this);
    this.selectedOrderOption = this.selectedOrderOption.bind(this);
    this.renderSortByDropdown = this.renderSortByDropdown.bind(this);
    this.callSearchOptions = this.callSearchOptions.bind(this);
    this.toggleFilterButton = this.toggleFilterButton.bind(this);
    this.toggleFilterExpansion = this.toggleFilterExpansion.bind(this);
    this.renderCallTypeFilterMaybe = this.renderCallTypeFilterMaybe.bind(this);
    this.renderSpiderFilterMaybe = this.renderSpiderFilterMaybe.bind(this);
    this.renderEntryFeeFilterMaybe = this.renderEntryFeeFilterMaybe.bind(this);
    this.renderGeoFilterMaybe = this.renderGeoFilterMaybe.bind(this);
    this.renderCategoriesFilterMaybe = this.renderCategoriesFilterMaybe.bind(this);
    this.localStoreKey = this.localStoreKey.bind(this);
    this.setupFilters = this.setupFilters.bind(this);
    this.setLocalStorageFilters = this.setLocalStorageFilters.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.loginCheck = this.loginCheck.bind(this);
  }

  loginCheck(opts = {}) {
    if (!document.getElementById('user-data').dataset?.id) {
      this.setState({ isEmailModalOpen: true });
      return false;
    }
    
    if (opts.subscriptionOnly && !(/true/).test(document.getElementById('user-data').dataset?.s)) {
      window.location.href = '/pricing';
      return false;
    }

    return true;
  }

  setupFilters() {
    let filters = {};
    let localStore = localStorage.getItem(this.localStoreKey());

    if (localStore) {
      filters = JSON.parse(localStore);
    } else {
      localStorage.setItem(
        this.localStoreKey(),
        JSON.stringify(filters)
      );
    }

    let de_dup = this.props.de_dup;
    filters.de_dup = !!de_dup;

    let savedSearchParams = {};
    
    // TODO: remove localStorage stuff once we're sure we don't need it
    if (document.getElementById('saved-search-param')) {
      savedSearchParams = JSON.parse(document.getElementById('saved-search-param').dataset.searchDefaults)
    }

    if (savedSearchParams.call_name) { filters.call_name = savedSearchParams.call_name }
    if (savedSearchParams.address) { filters.address = savedSearchParams.address }
    if (savedSearchParams.radius) { filters.radius = savedSearchParams.radius }
    if (savedSearchParams.entry_deadline_start) { filters.entry_deadline_start = new Date(savedSearchParams.entry_deadline_start) }

    if (savedSearchParams.entry_fee_start) { filters.entry_fee_start = savedSearchParams.entry_fee_start }
    if (savedSearchParams.entry_fee_end) { filters.entry_fee_end = savedSearchParams.entry_fee_end }

    if (savedSearchParams.kinds) { filters.kinds = savedSearchParams.kinds }

    if (savedSearchParams.call_type_ids) { filters.call_type_ids = savedSearchParams.call_type_ids }

    let call_types = Object.assign([], this.props.call_types);
    if (filters.call_type_ids) {
      call_types.map(
        callType => callType.selected = filters.call_type_ids.includes(String(callType.id))
      )
    }

    let categories = Object.assign([], this.props.categories);
    if (filters.kinds) {
      categories.map(
        category => category.selected = filters.kinds.includes(String(category.id))
      )
    }

    let orderOptions = Object.assign([], this.props.orderOptions);
    filters.order_option = filters.order_option || '';
    orderOptions.map(orderOption => orderOption.selected = filters.order_option.name == orderOption.name);
    if (!orderOptions.find(orderOption => orderOption.selected)) {
      orderOptions.find(orderOption => orderOption.name == 'Deadline (soonest)').selected = true;
    }

    let entry_fee_range = {};
    if (filters.entry_fee_start) {
      entry_fee_range.min = (filters.entry_fee_start / 100);
    } else {
      entry_fee_range.min = 0;
    }
    if (filters.entry_fee_end) {
      entry_fee_range.max = (filters.entry_fee_end / 100);
    } else {
      entry_fee_range.max = 1000;
    }

    let spiders = Object.assign([], this.props.spiders);
    if (filters.spiders) {
      spiders.map(
        spider => spider.selected = filters.spiders.includes(spider.id)
      )
    }

    this.setState({
      de_dup: filters.de_dup || false,
      searchVal: filters.call_name || '',
      address: filters.address || undefined,
      radius: filters.radius || 1000,
      activeFilterSection: filters.activeFilterSection || 'call_types',
      call_types: call_types,
      categories: categories,
      orderOptions: orderOptions,
      start_at_start: filters.start_at_start,
      entry_deadline_start: filters.entry_deadline_start,
      entry_fee_range: entry_fee_range
    });
  }

  resetFilters() {
    localStorage.setItem(this.localStoreKey(), JSON.stringify({}));
    location.reload();
  }

  componentWillMount() {
    this.setupFilters()
    this.setState({
      calls: []
    });
  }

  componentWillUnmount() {
    if (!this.state.preserveLocalStorageFilters) {
      localStorage.clear();
    }
  }

  // TODO: display pagination or reset page when results returned that make our current page # greater than pages returned
  currentPage() {
    return (this.state?.pagination && this.state.pagination.current) || this.props.page;
  }

  toggleFilterExpansion(e) {
    this.setState({ filterExpanded: !this.state.filterExpanded });
  }

  toggleFilterButton() {
    if (!this.state.pagination) {
      return (<span></span>);
    }

    return (
      <div className="flex flex-row justify-between" style={{width: '100%'}}>
        <div className=''>
          <strong className='text-black btn btn-link cursor-pointer dark:text-white' onClick={ this.toggleFilterExpansion }>
          <FontAwesomeIcon icon={faSliders} />
            &nbsp;
            Filters
          </strong>
          {/* { (this.state.pagination && (this.state.pagination.count || this.state.pagination.count == 0)) ? <span className="">
            { this.state.pagination.count } calls
          </span> : '' } */}
        </div>
        <div>
          { this.renderSortByDropdown() }
        </div>
      </div>
    );
  }

  setLocalStorageFilters() {
    this.setState({ preserveLocalStorageFilters: true });

    let filters = this.callSearchOptions();
    delete filters.page;
    delete filters.authenticity_token;
    filters.activeFilterSection = this.state.activeFilterSection;
    localStorage.setItem(this.localStoreKey(), JSON.stringify(filters));
  }

  selectedSpiders() {
    if (this.state?.spiders) {
      return this.state.spiders.filter(spider => spider.selected);
    } else {
      return [];
    }
  }

  selectedCallTypes() {
    if (this.state?.call_types) {
      return this.state.call_types.filter(type => type.selected);
    } else {
      return [];
    }
  }

  selectedCategories() {
    if (this.state?.categories) {
      return this.state.categories.filter(type => type.selected);
    } else {
      return [];
    }
  }

  selectedOrderOption() {
    if (!this.state?.orderOptions) {return}
    return this.state.orderOptions.find(option => option.selected);
  }

  renderSortByDropdown() {
    let thisComponent = this;

    let isSelected = function(orderOptionName) {
      return thisComponent.selectedOrderOption().name == orderOptionName;
    }

    let selectOrderOption = function(orderOptionName) {
      let orderOptions = [...thisComponent.state.orderOptions];
      orderOptions.forEach(option => {
        if (option.name === orderOptionName) {
          option.selected = true;
        } else {
          option.selected = false;
        }
      });
      thisComponent.setState({ orderOptions: orderOptions, showFilterDropdownOptions: false  });
      thisComponent.getCalls();
    }

    if (!this.state.pagination) {
      return (<span></span>);
    }

    return (
      <div className="border-y-2  border-black relative inline-block text-left">
        <div>
          <button onClick={ () => { thisComponent.setState({ showFilterDropdownOptions: !this.state.showFilterDropdownOptions }) } } type="button" className="border-none	inline-flex w-full font-medium justify-center gap-x-1.5 rounded-md px-3 py-3 text-sm border-none text-black dark:text-white" id="menu-button" aria-expanded="true" aria-haspopup="true">
            {this.selectedOrderOption().name.toLowerCase()}
            <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
            </svg>
          </button>
        </div>

        <div className={`${ this.state.showFilterDropdownOptions ? '' : 'hidden' } absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
          <div className="py-1" role="none">
            { thisComponent.state.orderOptions.map(orderOption => {
              return (
                <a key={orderOption.name} onClick={ function(e) { e.preventDefault(); thisComponent.loginCheck({ subscriptionOnly: true }) && selectOrderOption(orderOption.name) } } className="text-gray-700 block px-4 py-2 text-sm cursor-pointer" role="menuitem" tabIndex="-1" id="menu-item-0">
                  { isSelected(orderOption.name) && <strong>{orderOption.name.toLowerCase()}</strong> }
                  { !isSelected(orderOption.name) && <span>{orderOption.name.toLowerCase()}</span> }
                </a>
              )
            }) }
          </div>
        </div>
      </div>
    );
  }

  toggleCallType(name) {
    let thisComponent = this;

    let call_types = [...this.state.call_types];
    let callType = call_types.find(type => type.name === name);
    callType.selected = !callType.selected;
    this.setState({ call_types: call_types });
    this.getCalls();
  }

  toggleCategory(name) {
    let thisComponent = this;

    let categories = [...this.state.categories];
    let category = categories.find(type => type.name === name);
    category.selected = !category.selected;
    this.setState({ categories: categories });
    this.getCalls();
  }

  toggleSpider(name) {
    let thisComponent = this;

    let spiders = [...this.state.spiders];
    let spider = spiders.find(spider => spider.name === name);
    spider.selected = !spider.selected;
    this.setState({ spiders: spiders });
    this.getCalls();
  }

  renderSpiderDropdown() {
    let thisComponent = this;

    let isSelected = function(spiderName) {
      return thisComponent.selectedSpiders().find(spider => spider.name === spiderName);
    }

    return (
      <div className="hover-dropdown">
        <button className="hover-dropbtn btn btn-sm btn-light">Site</button>

        <div className="hover-dropdown-content">
          { this.state.spiders.map(function(spider) {
            return (
              <div key={spider.name} className="dropdown-item c-pointer d-flex justify-content-between" onClick={ function() { thisComponent.toggleSpider(spider.name) } }>
                <span>{spider.name}</span>
                { isSelected(spider.name) && <span className="fa fa-check fa-sm p-2 text-success mb-1"></span> }
                { !isSelected(spider.name) && <span className="fa fa-times fa-sm p-2 text-danger mb-1"></span> }
              </div>
            );
          }) }
        </div>
      </div>
    );
  }

  callSearchOptions() {
    let searchValInput = this.refs.searchValInput && this.refs.searchValInput.value;
    let geoSearchValInput = this.refs.geoSearchValInput && this.refs.geoSearchValInput.value;
    let geoSearchRadius = this.refs.geoSearchRadius && this.refs.geoSearchRadius.value;

    let options = {
      authenticity_token: internalApiService.CSRF(document),
      call_name: searchValInput,
      de_dup: this.state.de_dup,
      address: geoSearchValInput || this.state.address,
      radius: geoSearchRadius || this.state.radius,
      favorites: this.props.favorites,
      page: this.currentPage(),
      call_type_ids: this.selectedCallTypes().map(type => type.id),
      kinds: this.selectedCategories().map(type => type.id),
      spiders: this.selectedSpiders().map(spider => spider.id),
      order_option: this.selectedOrderOption(),
      entry_deadline_start: this.state.entry_deadline_start,
      start_at_start: this.state.start_at_start,
      entry_fee_start: this.state.entry_fee_range && (this.state.entry_fee_range.min * 100),
      entry_fee_end: this.state.entry_fee_range && (this.state.entry_fee_range.max * 100),
     }

    return options;
  }

  renderFilters(opts) {
    let thisComponent = this;

    let inactiveClasses = "border-transparent hover:text-gray-600 dark:text-white dark:hover:text-gray-300";
    let activeClasses = "active text-blue-600 light:border-blue-600 dark:border-white dark:text-white";

    let selectFilterSection = function(name) {
      thisComponent.setState({ activeFilterSection: name });
    }

    let className;
    if (opts.hidden) {
      className = 'hidden'
    } else {
      className = ''
    }

    let renderDateFilters = function() {
      if (thisComponent.state.activeFilterSection != 'dates') {
        return;
      }
      
      return (
        <div className="text-left p-2 mt-4">
          <label>Deadline After</label>
          <div className="my-2 form-group">
            <DatePicker className="form-control" selected={thisComponent.state.entry_deadline_start} onChange={(date) => thisComponent.loginCheck({ subscriptionOnly: true }) && thisComponent.setState({ entry_deadline_start: date }, thisComponent.getCalls) } />
          </div>
          {/* <label>Event Starts After</label>
          <div className="my-2">
            <DatePicker selected={thisComponent.state.start_date_start} onChange={(date) => thisComponent.setState({ start_date_start: date }) } />
          </div> */}
        </div>
      );
    }

    return (
      <div className={ `text-xs md:text-sm font-medium text-center text-gray-500 dark:text-gray-400 dark:border-gray-700 ${className}` }>
          <ul className="flex flex-wrap -mb-px list-none">
              <li className="">
                  <a href="#" onClick={function(e){ e.preventDefault(); selectFilterSection('call_types') }} className={ `inline-block p-4 border-b-2 rounded-t-lg ${ (this.state.activeFilterSection == 'call_types' ? activeClasses : inactiveClasses) }` }>Type</a>
              </li>
              <li className="">
                  <a href="#"  onClick={function(e){ e.preventDefault(); selectFilterSection('dates') }}className={ `inline-block p-4 border-b-2 rounded-t-lg ${ (this.state.activeFilterSection == 'dates' ? activeClasses : inactiveClasses) }` } aria-current="page">Dates</a>
              </li>
              <li className="">
                  <a href="#" onClick={function(e){ e.preventDefault(); selectFilterSection('entry_fee') }} className={ `inline-block p-4 border-b-2 rounded-t-lg ${ (this.state.activeFilterSection == 'entry_fee' ? activeClasses : inactiveClasses) }` }>Fee</a>
              </li>
              <li className="">
                  <a href="#" onClick={function(e){ e.preventDefault(); selectFilterSection('geo') }} className={ `inline-block p-4 border-b-2 rounded-t-lg ${ (this.state.activeFilterSection == 'geo' ? activeClasses : inactiveClasses) }` }>Geo</a>
              </li>
              <li className="">
                  <a href="#" onClick={function(e){ e.preventDefault(); selectFilterSection('category') }} className={ `inline-block p-4 border-b-2 rounded-t-lg ${ (this.state.activeFilterSection == 'category' ? activeClasses : inactiveClasses) }` }>Kind</a>
              </li>
              {/* <li>
                  <a className="inline-block p-4 text-gray-400 rounded-t-lg cursor-not-allowed dark:text-gray-500">Disabled</a>
              </li> */}
          </ul>


          <div className='mb-2'>
            { renderDateFilters() }
            { this.renderCallTypeFilterMaybe() }
            {/* { this.renderSpiderFilterMaybe() */}
            { this.renderEntryFeeFilterMaybe() }
            { this.renderGeoFilterMaybe() }
            { this.renderCategoriesFilterMaybe() }
          </div>
      </div>
    );
  }

  renderCallTypeFilterMaybe() {
    let thisComponent = this;

    if (this.state.activeFilterSection != 'call_types') {
      return;
    }

    let renderCallType = function(callType) {
      return (
        <span key={callType.name} className="p-2 block cursor-pointer" onClick={function() { thisComponent.loginCheck({ subscriptionOnly: true }) && thisComponent.toggleCallType(callType.name)}}>
          <input className=""
                 checked={callType.selected}
                 onChange={function() {}}
                 type="checkbox" />
          <span className="mx-2"> {callType.name}</span>
        </span>
      )
    }

    return (
      <div className='w-full my-4 text-left'>
        { this.state.call_types.map(renderCallType) }
      </div>
    )
  }

  renderCategoriesFilterMaybe() {
    let thisComponent = this;

    if (this.state.activeFilterSection != 'category') {
      return;
    }

    let renderCategory = function(category) {
      return (
        <span key={category.name} className="p-2 block cursor-pointer" onClick={function() { thisComponent.loginCheck({ subscriptionOnly: true }) && thisComponent.toggleCategory(category.name)}}>
          <input className=""
                 checked={category.selected}
                 onChange={function() {}}
                 type="checkbox" />
          <span className="mx-2"> {category.name}</span>
        </span>
      )
    }

    return (
      <div className='w-full my-4 text-left'>
        { this.state.categories.map(renderCategory) }
      </div>
    )
  }

  renderSpiderFilterMaybe() {
    let thisComponent = this;

    if (this.state.activeFilterSection != 'spiders') {
      return;
    }

    let renderSpider = function(spider) {
      return (
        <div key={spider.name} className="m-4 c-pointer" onClick={function() {thisComponent.toggleSpider(spider.name)}}>
          <input className="form-check-input boolean optional c-pointer"
                 checked={spider.selected}
                 onChange={function() {}}
                 type="checkbox" />
          <span>{spider.name}</span>
        </div>
      )
    }

    return (
      <div className='d-md-flex'>
        { this.state.spiders.map(renderSpider) }
      </div>
    )
  }

  renderEntryFeeFilterMaybe() {
    let thisComponent = this;

    if (this.state.activeFilterSection != 'entry_fee') {
      return;
    }

    return (
<div className='p-2 mt-4'>
        <div className='text-left'>
          <label>Minimum Entry Fee ($)</label>
          <div className=''>
            <input id="entry_fee_min"
                   className="form-control mb-2"
                   type='number'
                   onChange={(e) => { thisComponent.loginCheck({ subscriptionOnly: true }) && thisComponent.setState({ entry_fee_range: { min: e.target.value, max: this.state.entry_fee_range.max } }, thisComponent.getCalls) }}
                   min={0}
                   max={1000}
                   defaultValue={this.state.entry_fee_range.min}
                   placeholder={'Minimum Fee'}
                   style={{}}>
              </input>
            </div>
            <label>Maximum Entry Fee ($)</label>
            <div className=''>
              <input id="entry_fee_max"
                     className="form-control mb-2"
                     type='number'
                     onChange={(e) => { thisComponent.loginCheck({ subscriptionOnly: true }) && thisComponent.setState({ entry_fee_range: { min: this.state.entry_fee_range.min, max: e.target.value } }, thisComponent.getCalls) }}
                     min={0}
                     max={1000}
                     defaultValue={this.state.entry_fee_range.max}
                     style={{}}>
              </input>
            </div>
        </div>
      </div>
    );
  }

  renderGeoFilterMaybe() {
    let thisComponent = this;

    if (this.state.activeFilterSection != 'geo') {
      return;
    }


    return (
      <div className='p-2 mt-4'>
        <div className='text-left'>
          <label>Starting Point</label>
          <div className=''>
            <input id="geo_search_bar"
                   className="form-control mb-2"
                   type='string'
                   onChange={(e) => { thisComponent.loginCheck({ subscriptionOnly: true }) && thisComponent.setState({ address: e.target.value }, thisComponent.getCalls) }}
                   ref='geoSearchValInput'
                   defaultValue={this.state.address}
                   placeholder={'Enter a valid address'}
                   style={{}}>
              </input>
            </div>
            <label>Radius (miles)</label>
            <div className=''>
              <input id="geo_search_bar"
                     className="form-control mb-2"
                     type='number'
                     defaultValue={this.state.radius}
                     onChange={(e) => { thisComponent.loginCheck({ subscriptionOnly: true }) && thisComponent.setState({ radius: e.target.value }, thisComponent.getCalls) }}
                     min={1}
                     max={250}
                     ref='geoSearchRadius'
                     style={{}}>
              </input>
            </div>
        </div>
      </div>
    )
  }

  renderFilterSection() {
    let searchValInput = this.refs.searchValInput && this.refs.searchValInput.value;

    return (
      <div>
        { this.renderFilters({ hidden: !this.state.filterExpanded }) }
        {/* <br/> */}
        {/* <hr className='m-0'/> */}
        { (this.state?.pagination && (this.state.pagination.count || this.state.pagination.count == 0)) && this.state.filtersApplied ? <div className="text-slate-400">
            <br/>
            <p className='pl-2 pt-1 text-slate-400' dangerouslySetInnerHTML={ { __html: `🔍 ${ this.state.pagination.count } calls ${(searchValInput ? `found for '<strong>${searchValInput}</strong>'` : 'matching your filters')}` } }></p>
          </div> : '' }
        {/* { this.state.pagination && <div className="text-slate-400">
          <p className='pl-2 pt-1'>page { this.state.pagination.current } of { this.state.pagination.pages }</p>
        </div> } */}
      </div>
    );
  }
}
