import React, { useEffect, useState } from 'react'

import PropTypes from "prop-types";
import Pagination from "./Pagination";
import BaseCallSearch from "./BaseCallSearch";
import HomepageCall from "./HomepageCall";
import EmailCaptureModal from './EmailCaptureModal';
import toast, { Toaster } from 'react-hot-toast';
import $ from 'jquery';
import { ThreeDots } from 'react-loader-spinner';
import { Typewriter } from 'react-simple-typewriter';

export default class HomepageCallSearch extends BaseCallSearch {
  static propTypes = {
    page: PropTypes.number.isRequired,
    orderOptions: PropTypes.array.isRequired,
    call_types: PropTypes.array.isRequired,
    call_type_emojis: PropTypes.object.isRequired,
    preloaded_calls: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.getCalls = this.getCalls.bind(this);
    this.renderFiltersTop = this.renderFiltersTop.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      ...this.state,
      isEmailModalOpen: false
    };
  }

  // TODO: make specific to deploy
  localStoreKey() {
    return 'artcallHomepageCallSearch';
  }

  componentDidMount() {
    if (this.props.preloaded_calls) {
      this.setState({
        getError: false,
        calls: this.props.preloaded_calls.records,
        filtersApplied: this.props.preloaded_calls.user_input,
        pagination: this.props.preloaded_calls.pagination,
        fetchingCalls: false
      })
    }
    else {
      this.getCalls()
    }
  }

  getCalls(e) {
    e && e.preventDefault();
    let thisComponent = this;
    thisComponent.setState({ fetchingCalls: true });

    $.get("/api/v1/public/calls.json", this.callSearchOptions())
        .done(function(response) {
                  thisComponent.setState({
                    getError: false,
                    calls: response.records,
                    filtersApplied: response.user_input,
                    pagination: response.pagination,
                    fetchingCalls: false
                  });
                  // thisComponent.refs.submit.blur();
                })
        .fail(function(response) {
                thisComponent.setState({
                  getError: 'Oops, something went wrong.',
                  fetchingCalls: false
                });
              })
        .always(function() {
          $(thisComponent.refs.submit).prop('disabled', false);
          thisComponent.setState({ fetchingCalls: false });
        });
  }

  handleSubmit(e) {
    e.preventDefault();
    $(this.refs.submit).prop('disabled', true);
    this.getCalls();
  }

  render() {    
    let thisComponent = this;
    let placeholder;

    if (this.state.pagination) {
      placeholder = ` Search opportunities...`;
    }

    return (
      <div className="max-w-6xl mx-auto rounded-md">
        {false && <div className="text-center">
            <p className="text-sm md:text-base" style={{ margin: 'auto 0', fontWeight: 'normal' }}>
            Are you looking for {' '}
            <span style={{ }}>
              {/* Style will be inherited from the parent element */}
              <Typewriter
                words={['Exhibitions?', 'Residencies?', 'Competitions?', 'Fairs and Festivals?', 'Publications?']}
                loop={5}
                cursor={false}
                cursorStyle='_'
                typeSpeed={100}
                deleteSpeed={100}
                delaySpeed={2000}
                onLoopDone={() => {}}
                onType={() => {}}
              />
            </span>
          </p>
        </div>}

        <div className="filter-container mb-3 py-4">
          <form onSubmit={ (e) => { if (thisComponent.loginCheck({ subscriptionOnly: true })) { thisComponent.getCalls(e) } else { e.preventDefault() } } }>
            <div className=''>
              <div className='form-group'>
                <input id="search_bar"
                       autoFocus={false}
                       autoComplete="off"
                       className="form-control w-full py-2 px-4"
                       type='string'
                       ref='searchValInput'
                       defaultValue={ this.state.searchVal }
                       placeholder={placeholder}
                       style={{ border: 'none !important' }}>
                </input>
                {/* <div className="" onClick={this.getCalls}>
                  <span className="">
                    <button id="search_button" name="button" type="button" className="">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </div> */}
              </div>
            </div>
          </form>

          <div className="">
            <div className="">
              { this.renderFiltersTop() }
              { this.renderFilterSection() }
            </div>
          </div>
        </div>

        <div className="">
          { this.state.calls && !this.state.fetchingCalls && this.state.calls.map(call => (
            <HomepageCall key={call.id} call={call} searchText={ this.refs.searchValInput && this.refs.searchValInput.value } />
          )) }

          { this.state.fetchingCalls && <div className="w-full m-auto">
              <div className="mx-auto">
                <ThreeDots 
                  height="80" 
                  width="80" 
                  radius="9"
                  color="rgb(37 99 235)" 
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{dispaly: 'flex', justifyContent: 'center'}}
                  wrapperClassName="mx-auto"
                  visible={true}
                  />
              </div>
          </div> }
        </div>

        { !this.state.fetchingCalls && this.state.pagination && this.state.pagination.pages > 1 && <Pagination pagination={this.state.pagination} onClick={function(e) { thisComponent.setLocalStorageFilters(); if (!thisComponent.loginCheck()) { e.preventDefault() } }} /> }
        <Toaster />
        {!document.getElementById('user-data').dataset.id && (
          <EmailCaptureModal isOpen={this.state.isEmailModalOpen} onClose={() => this.setState({ isEmailModalOpen: false })} />
        )}
	    </div>
    );
  }

  renderFiltersTop() {
    let thisComponent = this;

    return (
      <div className="">
        { this.toggleFilterButton() }
      </div>
    )
  }
}
