import axios from 'axios';
// import * as Sentry from '@sentry/react';

const internalApiService = {
  CSRF: (document) => internalApiService.getMetaContent(document, 'csrf-token'),

  getMetaContent: (document, name) => {
    const metas = document.getElementsByTagName('meta');

    for (const meta of metas) {
      if (meta && meta.getAttribute('name') === name) {
        return meta.getAttribute('content');
      }
    }
  },

  get: (route, params, callbacks = {}) =>
    axios
      .get(route, {
        params: {
          authenticity_token: internalApiService.CSRF(document),
          ...params,
        },
      })
      .then((response) => {
        if (callbacks.onSuccess) {
          callbacks.onSuccess(response);
        } else {
          return response;
        }
      })
      .catch((error) => {
        // Sentry.captureException(error);
        if (callbacks.onCatch) {
          callbacks.onCatch(error);
        } else {
          alert('Something went wrong.');
          console.log(error);
          return error;
        }
      }),

  post: (route, params, callbacks = {}) =>
    axios
      .post(route, {
        authenticity_token: internalApiService.CSRF(document),
        ...params,
      })
      .then((response) => {
        if (callbacks.onSuccess) {
          callbacks.onSuccess(response);
        } else {
          return response;
        }
      })
      .catch((error) => {
        // Sentry.captureException(error);
        if (callbacks.onCatch) {
          callbacks.onCatch(error);
        } else {
          alert('Something went wrong.');
          console.log(error);
          return error;
        }
      }),

  delete: (route, params) =>
    axios
      .delete(route, {
        params: {
          authenticity_token: internalApiService.CSRF(document),
          ...params,
        },
      })
      .then((response) => response)
      .catch((error) => {
        // Sentry.captureException(error);
        alert('Something went wrong.');
        console.log(error);
        return error;
      }),

  put: (route, params, callbacks = {}) =>
    axios
      .put(route, {
        authenticity_token: internalApiService.CSRF(document),
        ...params,
      })
      .then((response) => {
        if (callbacks.onSuccess) {
          callbacks.onSuccess(response);
        } else {
          return response;
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        if (callbacks.onCatch) {
          callbacks.onCatch(error);
        } else {
          alert('Something went wrong.');
          console.log(error);
          return error;
        }
      }),
};
export default internalApiService;
