import React, { Fragment, useRef, useState } from 'react';
import PropTypes from "prop-types";
import { Dialog, Transition } from '@headlessui/react';

import SortableComponent from './SortableComponent';

import axios from 'axios';

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};

export default class ManagePieces extends React.Component {
  static propTypes = {
    parentComponent: PropTypes.object.isRequired,
    className: PropTypes.string,
    piece: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.deletePiece = this.deletePiece.bind(this);
    this.updatePiece = this.updatePiece.bind(this);
    this.resetPieceImages = this.resetPieceImages.bind(this);
  }

  componentWillMount() {
    let initialWidth, initialHeight;

    if (this.props.piece.piece_images[0]?.dimensions) {
      initialHeight = this.props.piece.piece_images[0].dimensions.height
      initialWidth = this.props.piece.piece_images[0].dimensions.width

      let multiplier = initialWidth / initialHeight;
      initialWidth = multiplier * 100;
      initialHeight = 100;

      initialWidth += 13;
      initialHeight += 13;
    } else {
      initialWidth = 160;
      initialHeight = 80;
    }
    
    this.setState({
      modal: false,
      piece: this.props.piece,
      dimensions: {
        width: initialWidth,
        height: initialHeight
      }
    });
  }

  toggle() {
    let thisComponent = this;
    let closing = new Boolean(thisComponent.state.modal);

    let updateParent = function() {
      if (closing) { // handle deleting here too instead of in deleteRequest?
        thisComponent.props.parentComponent.pieceChanged(thisComponent.state.piece);
      }
    }

    this.setState({
      modal: !this.state.modal
    }, updateParent);
  }

  updatePiece(e) {
    e.preventDefault();
    let thisComponent = this;

    // too: hide medium to ensure modal on mobile fits???
    let pieceData = {
      title: document.getElementById('piece_title').value,
      medium: document.getElementById('piece_medium').value,
      description: document.getElementById('piece_description').value,
      image_ids_in_position_order: document.getElementById('piece_image_ids_in_position_order').value,
    }

    let makeRequest = function() {
      return axios({
        method: 'patch',
        url: `/api/v1/pieces/${thisComponent.state.piece.id}`,
        data: {
          entry_id: thisComponent.props.entry_id,
          piece: pieceData
        },
        config: { headers: { 'Content-Type': 'application/json' } }
      });
    };

    makeRequest().then(response => {
      thisComponent.setState({
        errors: response.data.errors,
        piece: response.data.piece
      }, this.toggle);
    }).catch(error => {
      alert(`Something went wrong: ${error}`);
    });
  }

  deletePiece() {
    let thisComponent = this;

    let deleteRequest = () => {
      return axios({
        method: 'delete',
        url: `/api/v1/pieces/${this.state.piece.id}`
      });
    }

    deleteRequest().then(response => {
      thisComponent.toggle();
      thisComponent.props.parentComponent.pieceRemoved(this.state.piece.id);
    }).catch(error => {
      alert(`Something went wrong: ${error}`);
    });
  }

  renderForm() {
    let thisComponent = this;

    let renderPieceImages = function() {
      return (
        <div>
          <div className="d-none">
            <div className="form-group hidden piece_image_ids_in_position_order">
              <input className="form-control hidden" type="hidden" name="piece[image_ids_in_position_order]" id="piece_image_ids_in_position_order" value="" />
            </div>
          </div>
          <div className='carousel-images'>
            <SortableComponent ref='sortableImages' parentComponent={thisComponent} piece_id={ thisComponent.state.piece.id } items={ thisComponent.state.piece.piece_images } helperClass='modal-sortable' />
          </div>
        </div>
      );
    }

    return (
      <Transition.Root show={thisComponent.state.modal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="border-solid border-2 border-indigo-600 relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg" style={ { width: '90%', top: '75px', backgroundColor: getComputedStyle(document.querySelector('body')).backgroundColor } }>
                  <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        {/* <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Manage Piece
                        </Dialog.Title> */}
                        <div className="mt-2">
                          <p className="text-sm text-gray-500"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <form>
                      <div className="form-group">
                        <label className="block text-sm font-bold mb-2" htmlFor="piece_title">Title</label>
                        <input className="form-control w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-1" type="string" name="title" id="piece_title" placeholder="" defaultValue={ this.state.piece.title } />
                      </div>
                      <div className="form-group">
                        <label className="block text-sm font-bold mb-2" htmlFor="piece_title">Medium</label>
                        <input className="form-control w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-1" type="string" name="medium"  id="piece_medium" placeholder="" defaultValue={ this.state.piece.medium } />
                      </div>
                      <div className='form-group' style={{display: 'none'}}>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="piece_description">Description</label>
                        <div className='row'>
                          <div className="col-lg-12">
                            <input id={ 'piece_description' } defaultValue={ this.state.piece.description } type="hidden"/>
                            <trix-editor input={ 'piece_description' }></trix-editor>
                          </div>
                        </div>
                      </div>
                      { this.state.piece.id && renderPieceImages() }
                    </form>
                  </div>
                  <div className="p-2">
                    <div className='flex justify-between'>
                      <a className="btn btn-primary" onClick={this.updatePiece}>Save</a>

                      <a className="btn btn-danger" onClick={this.deletePiece}>Delete</a>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  resetPieceImages() {
    // TODO; cleanup.
    let piece = Object.assign({}, this.state.piece);
    piece.piece_images = this.refs.sortableImages.state.pieceImages.sort(
      function(a, b) { return a.position - b.position; }
    );
    this.setState({ piece: piece });
  }

  renderModal() {
    return this.renderForm();
  }

  render() { 
    const handleImageLoad = (event) => {
      this.setState(prevState => ({
        dimensions: {
          width: event.target.width + 13,
          height: event.target.height + 13
        }
      }));
    };

    return (
      <div key={this.state.piece.id} className="col-md-2 mb-4 piece">
        <div style={{display: "flex", flexDirection: "column"}}>
        <h6 onClick={this.toggle}>
          { this.state.piece.title || <div></div> }
        </h6>
        <div onClick={this.toggle} style={{boxSizing:"border-box", display: "inline-block", flexDirection: "column", justifyContent: "center", alignItems: "center",
           backgroundColor: "#F5F5DC", cursor: 'pointer', width: (this.state.dimensions.width+"px"), height: (this.state.dimensions.height+"px"),
           border: "5px solid #402F1D" }}>
          { this.state.piece.piece_images[0] && (
            <img className="max-w-full h-auto" style={{boxSizing: "border-box", maxHeight: '100px', objectFit:"contain", border: "3px solid grey"}} src={ this.state.piece.piece_images[0].src } onLoad={handleImageLoad} />
          ) }
          { !this.state.piece.piece_images[0] && (
            <div style={{color: "grey", textAlign: "center", marginTop: '15%'}}>Upload Here</div>
          ) }
        </div>
        </div>
        { this.renderModal() }
      </div>
    );
  }
};
